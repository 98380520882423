import React from 'react';
import { Create, CreateProps, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const VendorCreate = (props: CreateProps) => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="merchant_id" validate={[required()]} />
          <TextInput source="website" />
          <ReferenceInput label="Market" source="market_id" reference="market" validate={[required()]}>
              <SelectInput optionText="name" />
          </ReferenceInput>
      </SimpleForm>
  </Create>
);
