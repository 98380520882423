import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { stringify } from 'query-string';
import { DataProvider, fetchUtils } from 'react-admin';

import dataConfig from './dataConfig';

const apiUrl = dataConfig.apiBaseUrl;

export const getDataProvider = (auth0: Auth0ContextInterface<User>) => {
  const getClient = async (url: string, data: any = {}) => {
    const accessToken = await auth0.getAccessTokenSilently()
    const options = {
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`
        },
        ...data
    }
    return fetchUtils.fetchJson(url, options)
  }

  const mapRecordId = (json: { data: any[]; }, resource: string) => (
    json.data.map((r: any) => ({ ...r, id: r[`${resource}_id`] }) )
  )

  return {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = `${resource}_id`;
        }
        const query = {
            sort: JSON.stringify([field, order]),
            limit: perPage,
            offset: (page - 1) * perPage,
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;

        return getClient(url).then(({ json }) => ({
            data: mapRecordId(json, resource),
            total: json.total,
        }));
    },

    getOne: (resource, params) =>
        getClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: { ...json.data, id: json.data[`${resource}_id`] }
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;
        return getClient(url).then(({ json }) => ({ data: mapRecordId(json, resource) }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return getClient(url).then(({ headers, json }) => ({
            data: json,
            total: 10,
        }));
    },

    update: (resource, params) =>
        getClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: { ...json.data, id: json.data[`${resource}_id`] } })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return getClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        getClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        getClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return getClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    }
  } as DataProvider;
}
