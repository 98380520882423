import React from 'react';
import { ReferenceField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

import LatTitle from '../common/LatTitle';



export const VendorShow = (props: ShowProps) => (
  <Show title={<LatTitle source="name" />} {...props}>
    <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="merchant_id" />
        <TextField source="website" />
        <ReferenceField label="Market" source="market_id" reference="market">
            <TextField source="name" />
        </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
