import { Identifier } from 'react-admin';

import dataConfig from './dataConfig';

export const approveRequest = async (token: string, requestId: Identifier) => {
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    return await fetch(`${dataConfig.apiBaseUrl}/invitation/${requestId}/approve`, { method: 'POST', headers })
}