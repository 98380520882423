import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Datagrid, DateField, EmailField, List, ListProps, SimpleList, TextField } from 'react-admin';

export const InvitationList = (props: ListProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <List {...props}>
      {isSmall ? (
        <SimpleList
            primaryText={record => record.name}
            secondaryText={record => record.about}
            tertiaryText={record => new Date(record.date_created).toLocaleDateString()}
        />
      ) : (
        <Datagrid rowClick="show">
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="about" />
            <TextField source="status" />
            <DateField source="date_created" />
        </Datagrid>
      )}
    </List>
  )
};
