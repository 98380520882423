import React from "react";
import { ShowProps, Show, SimpleShowLayout, TextField, BooleanField, NumberField } from "react-admin";
import LatTitle from "../common/LatTitle";

export const MarketShow = (props: ShowProps) => (
  <Show title={<LatTitle source="name" />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="market_code" />
      <TextField source="description" />
      <BooleanField source="is_coming_soon" />
      <NumberField source="display_index" />
    </SimpleShowLayout>
  </Show>
);
