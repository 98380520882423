import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MailIcon from '@material-ui/icons/Mail';
import PlaceIcon from '@material-ui/icons/Place';
import React from 'react';
import { Admin, Loading, Resource } from 'react-admin';

import { InvitationList, InvitationShow } from './components/invitations';
import { MarketList, MarketShow } from './components/market';
import { VendorCreate, VendorEdit, VendorList, VendorShow } from './components/vendors';
import { getAuthProvider } from './utils/authProvider';
import { getDataProvider } from './utils/dataProvider';
import theme from './utils/theme';

const App = () => {
  const auth0 = useAuth0()
  const authProvider = getAuthProvider(auth0)
  const dataProvider = getDataProvider(auth0)
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider} theme={theme}>
      <Resource name="market" list={MarketList} show={MarketShow} icon={PlaceIcon} />
      <Resource name="vendor" list={VendorList} show={VendorShow} create={VendorCreate} edit={VendorEdit} icon={LocalMallIcon} />
      <Resource name="invitation" list={InvitationList} show={InvitationShow} icon={MailIcon} />
    </Admin>
  )
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading loadingPrimary="Elevate your finances" loadingSecondary="Seek to be worth knowing" />,
});
