export type AuthConfig = {
  domain: string,
  clientID: string,
  redirectURI: string,
  audience: string,
  scope: string
}

const authConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectURI: process.env.REACT_APP_AUTH0_REDIRECT_URI,
  audience: 'https://latitude-api/',
  scope: 'write:invitations,read:invitations,read:vendors,write:vendors'
} as AuthConfig

export default authConfig