import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';
import * as React from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { Button } from 'rebass';

import { InvitationRequest } from '../../types';
import { approveRequest } from '../../utils/api';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton: FC<{ record?: InvitationRequest }> = ({ record }) => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const auth0 = useAuth0();

    const handleClick = async () => {
        const token = await auth0.getAccessTokenSilently();
        
        try  {
            const response = await approveRequest(token, record?.id || -1)
            if (response.status === 201) {
                notify('Request approved, invite code sent');
                redirectTo('/invitation');
            } else {
                notify('Error: Request not approved', 'warning')
            }
        } catch (e) {
            console.error(e);
            notify('Error: Request not approved', 'warning')
        }
    }
    return record && record.status === 'pending' ? (
        <Button
            backgroundColor="#009e93"
            onClick={handleClick}
            // disabled={loading}
            style={{cursor: 'pointer'}}
        >
            Approve
        </Button>
    ) : (
        <span />
    );
};

export default AcceptButton;