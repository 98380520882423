import React from 'react';
import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

import LatTitle from '../common/LatTitle';
import ApproveButton from './ApproveButton';

export const InvitationShow = (props: ShowProps) => (
  <Show title={<LatTitle source="name" />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="about" />
      <TextField source="status" />
      <ApproveButton />
    </SimpleShowLayout>
  </Show>
);
